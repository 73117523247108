var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: {
      'wang-editor': _vm.border,
      'wang-editor-dark': _vm.dark
    }
  }, [_c('div', {
    staticClass: "wangeditor-toolbar",
    style: {
      zIndex: _vm.zIndex + 1
    },
    attrs: {
      "id": `${_vm.id}-toolbar-container`
    }
  }), _c('div', {
    style: {
      height: _vm.height + 'px',
      zIndex: _vm.zIndex - 1,
      position: 'relative'
    },
    attrs: {
      "id": `${_vm.id}-editor-container`
    }
  }, [_vm.showHTML ? _c('monaco', {
    ref: "wangeditorMonaco",
    staticClass: "wang-editor-monaco",
    style: {
      zIndex: _vm.zIndex
    },
    attrs: {
      "autoFormat": "",
      "id": `${_vm.id}-editor-monaco`,
      "defaultTheme": _vm.dark ? 'vs-dark' : 'vs',
      "showHeader": false,
      "language": "html",
      "height": "100%",
      "showFullscreen": false
    },
    on: {
      "on-change": _vm.changeHTML
    },
    model: {
      value: _vm.dataEdit,
      callback: function ($$v) {
        _vm.dataEdit = $$v;
      },
      expression: "dataEdit"
    }
  }) : _vm._e()], 1), _vm.material ? _c('materialCenter', {
    attrs: {
      "maxSize": _vm.maxSize,
      "multiple": ""
    },
    on: {
      "on-change": _vm.selectImage
    },
    model: {
      value: _vm.showMaterialImage,
      callback: function ($$v) {
        _vm.showMaterialImage = $$v;
      },
      expression: "showMaterialImage"
    }
  }) : _vm._e(), _vm.material ? _c('materialCenter', {
    attrs: {
      "mode": "视频",
      "maxSize": _vm.maxSize
    },
    on: {
      "on-change": _vm.selectVideo
    },
    model: {
      value: _vm.showMaterialVideo,
      callback: function ($$v) {
        _vm.showMaterialVideo = $$v;
      },
      expression: "showMaterialVideo"
    }
  }) : _vm._e(), _c('Dropdown', {
    style: {
      position: 'absolute',
      top: _vm.top,
      left: _vm.left
    },
    attrs: {
      "trigger": "custom",
      "visible": _vm.showMention
    }
  }, [_c('DropdownMenu', {
    attrs: {
      "slot": "list"
    },
    slot: "list"
  }, [_c('div', {
    staticStyle: {
      "width": "200px"
    }
  }, [_c('Input', {
    ref: "searchInput",
    staticStyle: {
      "padding": "5px 8px 8px 8px"
    },
    attrs: {
      "placeholder": "输入用户名搜素"
    },
    on: {
      "on-change": _vm.searchUser
    },
    model: {
      value: _vm.searchKey,
      callback: function ($$v) {
        _vm.searchKey = $$v;
      },
      expression: "searchKey"
    }
  }), _vm._l(_vm.userList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "ivu-dropdown-item",
      staticStyle: {
        "overflow": "hidden"
      },
      on: {
        "click": function ($event) {
          return _vm.insertUser(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.nickname) + " "), _c('span', {
      staticStyle: {
        "margin-left": "8px",
        "color": "#ccc"
      }
    }, [_vm._v(_vm._s(item.username))])]);
  })], 2)])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };